.about-wrap {
    > .men-wrap {
        text-align: center;
        > h3 {
            font-size: 2vw;
            font-family: 'NanumSquareRoundEB';
            margin: 4vw 0 3.1vw 0;
        }
        > .men {
            width: 48vw;
            margin: 1vw auto;
            > .row {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid #929292;
                padding: 1.8vw 0;
                &:last-child {
                    border-bottom: none;
                }
                > .left {
                    flex: 1.5;
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    > p {
                        font-size: 1.25vw;
                        line-height: 1.7vw;
                        font-family: 'NanumSquareRoundEB';
                    }
                }
                > .right {
                    flex: 1;
                    text-align: left;
                    font-size: 1vw;
                    line-height: 1.8vw;
                }
            }
        }
    }
    > .history-wrap {
        background-color: #f3f2f8;
        padding: 4vw 0;
        > h3 {
            font-size: 2vw;
            margin-bottom: 4vw;
            text-align: center;
            font-family: 'NanumSquareRoundEB';
        }
        > .history {
            width: 48vw;
            margin: 0 auto;
            > .row {
                display: flex;
                flex-direction: row;
                & + .row {
                    margin-top: 1.8vw;
                }
                > .date {
                    width: 8vw;
                    font-size: 1.25vw;
                }
                > .arrow {
                    width: 8vw;
                    margin: 0 2vw 0 2vw;
                    padding-top: 0.4vw;
                    > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        > hr {
                            border-top: 1px solid #775efb;
                            flex: 1;
                        }
                        > .square {
                            width: 0.8vw;
                            height: 0.8vw;
                            border-radius: 50%;
                            background-color: #775efb;
                        }
                    }
                }
                > .content {
                    flex: 1;
                    > p {
                        font-size: 1.25vw;
                        margin-bottom: 1vw;
                    }
                    > span {
                        font-size: 0.8vw;
                        color: #646464;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .about-wrap {
        > .men-wrap {
            text-align: center;
            > h3 {
                font-size: 4.2vw;
                margin: 8vw 0 8vw 0;
            }
            > .men {
                width: 86.8vw;
                margin: 0 auto;
                > .row {
                    border-bottom: 1px solid #efefef;
                    padding: 8vw 0;
                    &:last-child {
                        border-bottom: none;
                    }
                    > .left {
                        flex: 0.65;
                        > p {
                            font-size: 3.7vw;
                            line-height: 5.3vw;
                        }
                    }
                    > .right {
                        flex: 1;
                        text-align: left;
                        font-size: 3.2vw;
                        line-height: 4.5vw;
                    }
                }
            }
        }
        > .history-wrap {
            background-color: #f3f2f8;
            padding: 8vw 0;
            > h3 {
                font-size: 4.2vw;
                margin-bottom: 8vw;
                text-align: center;
                font-family: 'NanumSquareRoundEB';
            }
            > .history {
                width: 86.8vw;
                margin: 0 auto;
                > .row {
                    display: flex;
                    flex-direction: row;
                    & + .row {
                        margin-top: 3vw;
                    }
                    > .date {
                        width: 20vw;
                        font-size: 3.7vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    > .arrow {
                        width: 10vw;
                        margin: 0 4vw 0 4vw;
                        padding-top: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        > div {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            > hr {
                                border-top: 2px solid #775efb;
                                flex: 1;
                            }
                            > .square {
                                display: none;
                            }
                        }
                    }
                    > .content {
                        flex: 1;
                        > p {
                            font-size: 3vw;
                            line-height: 5vw;
                            margin-bottom: 0;
                        }
                        > span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}