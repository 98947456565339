.footer-wrap {
    border-top: 1px solid #606060;
    padding-top: 5.2vw;
    padding-bottom: 6.2vw;
    > .in-wrap {
        max-width: 73vw;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        > .left-wrap {
            flex: 1;
            > .top {
                margin-bottom: 2.6vw;
                > a > img {
                    height: 1.3vw;
                }
            }
            > .middle {
                margin-bottom: 2.6vw;
                > div {
                    &:after {
                        clear: both;
                        content: '';
                        display: block;
                    }
                    ul {
                        list-style: none;
                        > li {
                            float: left;
                            > a {
                                color: #000000b3;
                                font-size: 1vw;
                                > span {
                                    font-family: 'NanumSquareRoundEB';
                                }
                            }
                        }
                        > li + li {
                            margin-left: 1.5vw;
                        }
                    }
                }
                > div + div {
                    margin-top: 1.3vw;
                }
            }
            > .bottom {
                > p {
                    font-size: 0.75vw;
                    color: #000000;
                    & + p {
                        margin-top: 0.8vw;
                    }
               }
               p:last-child {
                   color: #606060;
               }
            }
        }
        > .right-wrap {
            > a {
                > img {
                    width: 4.1vw;
                    height: 4.1vw;
                }
                & + a {
                    margin-left: 2vw;
                }
            }
        }
    }
}

.footer-wrap.black {
    > .in-wrap {
        > .left-wrap {
            > .middle {
                > div {
                    ul {
                        > li {
                            > a {
                                color: #fff;
                            }
                        }
                    }
                }
            }
            > .bottom {
                > p {
                    color: #fff;
               }
               p:last-child {
                   color: #fff;
               }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .footer-wrap.black {
        background-color: #000;
    }
    .footer-wrap {
        border-top: none;
        background-color: #e8e8e8;
        padding-top: 13.3vw;
        padding-bottom: 13.3vw;
        > .in-wrap {
            max-width: 85vw;
            display: block;
            > .left-wrap {
                > .top {
                    margin-bottom: 5.3vw;
                    > a > img {
                        height: 3.8vw;
                    }
                }
                > .middle {
                    > div {
                        ul {
                            > li {
                                > a {
                                    font-size: 3.7vw;
                                }
                            }
                            > li + li {
                                margin-left: 3vw;
                            }
                        }
                    }

                }
                > .bottom {
                    > p {
                        font-size: 3.2vw;
                        color: #909090;
                        > span {
                            display: block;
                            opacity: 0;
                            height: 4vw;
                        }
                        & + p {
                            margin-top: 4vw;
                        }
                        &:first-child {
                            margin-top: 4vw;
                        }
                        &:last-child {
                            color: #909090;
                        }
                    }
                }
            }
            > .right-wrap {
                margin-top: 8vw;
                display: flex;
                flex-direction: row;
                > a {
                    flex: 1;
                    > img {
                        width: 17vw;
                        height: 17vw;
                    }
                    & + a {
                        margin-left: 2vw;
                    }
                }
            }
        }
    }
  }