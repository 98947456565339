@font-face {
    font-family: "NanumSquareRoundB";
    src: url(./assets/fonts/NanumSquareRoundB.ttf) format("truetype");
}

@font-face {
    font-family: "NanumSquareRoundEB";
    src: url(./assets/fonts/NanumSquareRoundEB.ttf) format("truetype");
}

@font-face {
    font-family: "NanumSquareRoundL";
    src: url(./assets/fonts/NanumSquareRoundL.ttf) format("truetype");
}

@font-face {
    font-family: "NanumSquareRoundR";
    src: url(./assets/fonts/NanumSquareRoundR.ttf) format("truetype");
}

@font-face {
    font-family: "Noto";
    src: url(./assets/fonts/NotoSansThai-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "NotoB";
    src: url(./assets/fonts/NotoSansThai-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Pretendard";
    src: url(./assets/fonts/Pretendard-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "PretendardB";
    src: url(./assets/fonts/Pretendard-Bold.ttf) format("truetype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "NanumSquareRoundB";
}

a,
a:link,
a:visited {
    color: #fff;
    text-decoration: none;
}

body {
    /* background-color: #fff; */
}
body.dark {
    background-color: #111111;
}
body.black {
    background-color: #111111;
}

body.none .top-wrap {
    display: none;
}

body.none .footer-wrap {
    display: none;
}
