.body-wrap {
  &.dark {
    background-color: #161616;
  }

  .terms-container {
    padding: 50px 0px !important;
    color: #cccccc;
    width: 80vw;
    margin: 0 auto;
    .terms-ko,
    .terms-en {
      strong {
        display: block;
        margin: 10px 0px;
      }
      margin: 0 auto;
    }

    h1 {
      margin: 20px 0px;
    }

    .terms-th {
      color: #cccccc;
      margin: 0 auto;
      p {
        font-size: 14px;
        line-height: 23px;
        &.title-1 {
          font-size: 15px;
          margin: 20px 0;
        }
        &.title-2 {
          margin: 20px 0;
        }
        &.title-3 {
          margin: 20px 0 5px 0;
        }
        &.title-4 {
          margin: 20px 0;
        }
      }
    }

    .terms-ko,
    .terms-en {
      li {
        line-height: 1.5;
        margin-left: 1rem;
      }
      p {
        font-family: "Pretendard" !important;
        line-height: 1.5;
        &.title-1 {
          font-family: "PretendardB" !important;
        }
      }
    }
    .terms-th {
      p {
        font-family: "Noto" !important;
        &.title-1 {
          font-family: "NotoB" !important;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .terms-container {
    padding: 0 !important;

    .terms-en,
    .terms-ko,
    .terms-th {
      margin-left: 1rem;
      width: 80vw;
      margin: 0 auto;
      word-break: break-word;
    }
  }
}
