.body-wrap {
    &.dark {
        background-color: #161616;
    }
    .app-container {
        padding: 10px 50px;
        .apps {
            width: 80vw;
            color: #fff;
            margin: 0 auto;
            p {
                color: #cccccc;
                font-size: 14px;
                line-height: 23px;
                &.title-1 {
                    font-size: 15px;
                    margin: 20px 0;
                }
                &.title-2 {
                    margin: 20px 0;
                }
                &.title-3 {
                    margin: 20px 0 5px 0;
                }
                &.title-4 {
                    margin: 20px 0;
                }
            }
            li {
                color: #cccccc;
            }
        }

        .apps {
            p {
                font-family: "Pretendard" !important;
                &.title-1 {
                    font-family: "PretendardB" !important;
                }
            }
        }
        table {
            border-collapse: collapse;
            tr,
            td {
                border: 1px solid #676767;
                padding: 5px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .apps-container {
        padding: 0 !important;
        .apps {
            width: 80vw;
            margin: 0 auto;
            padding: 10px 20px;
            word-break: break-word;
        }
    }
}
