.body-wrap {
  &.dark {
    background-color: #161616;
  }
  .policy-container {
    padding: 50px 0px !important;
    width: 80vw;
    margin: auto;
    color: #cccccc;

    .policy-ko,
    .policy-en {
      line-height: 1.5;
      strong {
        display: block;
        margin: 10px 0px;
      }
      b {
        font-weight: 700;
        &.red {
          color: #b63f38;
        }
      }
    }

    .policy-th {
      color: #cccccc;
      p,
      ol {
        font-size: 14px;
        line-height: 23px;
        &.title-1 {
          font-size: 15px;
        }
        &.title-2 {
          margin: 20px 0;
        }
        &.title-3 {
          margin: 20px 0 5px 0;
        }
      }
    }

    .policy {
      p {
        font-family: "Pretendard" !important;
        &.title-1 {
          font-family: "PretendardB" !important;
        }
      }
    }
    .policy-th {
      p {
        font-family: "Noto" !important;
        &.title-1 {
          font-family: "NotoB" !important;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .policy-container {
    padding: 0 !important;
    .policy,
    .policy-th {
      width: 80vw;
      margin: 0 auto;
      padding: 10px 20px;
      word-break: break-word;
    }
  }
}
