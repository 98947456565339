.faq-wrap {
    > .in-wrap {
        max-width: 74vw;
        margin: 0 auto;
        > .category-wrap {
            margin-top: 3.1vw;
            margin-bottom: 5.2vw;
            > select {
                width: 18vw;
                border: 1px solid #000;
                padding: 0.7vw;
                font-size: 1.5vw;
                border-radius: 0.2vw;
            }
        }
        > .faq-list {
            margin-bottom: 4.1vw;
            > .faq {
                border-bottom: 1px solid #929292;
                > .top {
                    display: flex;
                    flex-direction: row;
                    padding: 2.4vw 0;
                    align-items: center;
                    cursor: pointer;
                    > .title {
                        flex: 1;
                        font-size: 1.25vw;
                        vertical-align: middle;
                    }
                    > img {
                        width: 2.5vw;
                        height: 2.5vw;
                        vertical-align: middle;
                    }
                }
                > .content {
                    margin-bottom: 1.7vw;
                    > pre {
                        font-size: 1vw;
                        color: #606060;
                        line-height: 1.5vw;
                        white-space: normal;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .faq-wrap {
        > .in-wrap {
            max-width: 92vw;
            > .category-wrap {
                margin-top: 7.2vw;
                margin-bottom: 7.2vw;
                > select {
                    border-radius: 1vw;
                    width: 45vw;
                    padding: 1.8vw;
                    font-size: 3.75vw;
                }
            }
            > .faq-list {
                margin-bottom: 8vw;
                > .faq {
                    > .top {
                        padding: 5vw 0;
                        > .title {
                            font-size: 3.73vw;
                        }
                        > img {
                            width: 5vw;
                            height: 5vw;
                        }
                    }
                    > .content {
                        margin-bottom: 4vw;
                        > pre {
                            font-size: 3vw;
                            line-height: 5vw;
                        }
                    }
                }
            }
        }
    }
}
