.point-guide {
    p {
        font-family: "Pretendard" !important;
    }
    li {
        font-family: "Pretendard" !important;
    }
    span {
        font-family: "Pretendard" !important;
    }
}

.menu_title {
    padding-bottom: 2px;
}
