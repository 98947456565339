.body-wrap {
    &.dark {
        background-color: #161616;
    }
    .guideline-container {
        padding: 50px 50px;
        .guideline,
        .guideline-th {
            color: #cccccc;
            p {
                font-size: 14px;
                line-height: 23px;
                &.title-1 {
                    font-size: 15px;
                }
                &.title-2 {
                    margin: 20px 0;
                }
                &.title-3 {
                    margin: 20px 0 5px 0;
                }
                &.title-4 {
                    margin: 20px 0;
                }
            }
        }

        .guideline {
            p {
                font-family: "Pretendard" !important;
                &.title-1 {
                    font-family: "PretendardB" !important;
                }
            }
        }
        .guideline-th {
            p {
                font-family: "Noto" !important;
                &.title-1 {
                    font-family: "NotoB" !important;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .guideline-container {
        padding: 0 !important;
        .guideline,
        .guideline-th {
            width: 80vw;
            padding: 10px 20px;
            margin: 0 auto;
            word-break: break-word;
        }
    }
}
