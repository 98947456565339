.home-wrap {
    > .video-wrap {
        background-color: #e9e9e9;
        position: relative;
        > video {
            width: 100%;
            &.mobile {
                display: none;
            }
        }
        > .info-wrap {
            position: absolute;
            text-align: center;
            bottom: 10vw;
            left: 50%;
            transform: translateX(-50%);
            > h1 {
                color: #fff;
                font-size: 3.3vw;
                margin-bottom: 3.3vw;
            }
            > div > a > img {
                height: 3.5vw;
                margin: 0 1vw;
            }
            > .shadow {
                width: 50vw;
                height: 20vw;
                background: radial-gradient(circle, rgba(169, 169, 169, 0.4) 0%, rgba(169, 169, 169, 0.3) 30%, rgba(169, 169, 169, 0) 80%);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                filter: blur(1.5rem);
                z-index: -1;
            }
        }
    }
    > .wte-wrap {
        background-color: #775efb;
        height: 44vw;
        display: flex;
        flex-direction: row;
        > .left-wrap {
            text-align: right;
            > img {
                margin-left: 10.5vw;
                height: 48vw;
            }
        }
        > .right-wrap {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > h1 {
                color: #fff;
                font-size: 3.3vw;
                font-family: "NanumSquareRoundEB";
            }
            > p {
                color: #fff;
                font-size: 1.8vw;
                line-height: 3vw;
                margin: 1.5vw 0 2.6vw;
            }
            > a > div {
                display: inline-block;
                background-color: #fff;
                border-radius: 1vw;
                padding: 1.4vw 1.8vw;
                vertical-align: middle;
                > span {
                    color: #775efb;
                    font-size: 1.6vw;
                    vertical-align: middle;
                }
                > img {
                    height: 1.9vw;
                    margin-left: 3.6vw;
                    vertical-align: middle;
                }
            }
        }
    }
    > .content-wrap {
        > .row {
            padding: 0 13vw;
            display: flex;
            flex-direction: row;
            .square {
                width: 100%;
                position: relative;
                overflow: hidden;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 110%;
                }
            }
            &:nth-child(2n) {
                background-color: #f8f6ff;
            }
            > .left {
                flex: 1;
                padding: 20px 20px 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            > .right {
                flex: 1;
                padding: 20px 0 20px 20px;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            img {
                position: absolute;
                width: 90%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            h1 {
                color: #775efb;
                font-size: 2.4vw;
                line-height: 4.2vw;
                font-family: "NanumSquareRoundEB";
            }
            p {
                font-size: 1.4vw;
                line-height: 2.8vw;
                color: #414d6b;
            }
            h1 + p {
                margin-top: 2vw;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .home-wrap {
        > .video-wrap {
            > video {
                &.mobile {
                    display: inherit;
                }
                &.pc {
                    display: none;
                }
            }
            > .info-wrap {
                bottom: 10vw;
                width: 100%;
                > h1 {
                    font-size: 5.3vw;
                    margin-bottom: 5.3vw;
                }
                > div > a > img {
                    height: 8.2vw;
                    margin: 0 3.2vw;
                }
                > .shadow {
                    width: 100vw;
                    height: 60vw;
                }
            }
        }
        > .wte-wrap {
            height: auto;
            display: block;
            padding-top: 16.2vw;
            padding-bottom: 24.2vw;
            > .left-wrap {
                text-align: center;
                > img {
                    width: 80vw;
                    height: auto;
                }
            }
            > .right-wrap {
                display: block;
                text-align: center;
                > h1 {
                    font-size: 6.4vw;
                }
                > p {
                    font-size: 4.2vw;
                    line-height: 6vw;
                    margin: 5vw 0 6vw;
                }
                > a > div {
                    padding: 4.2vw 7.7vw;
                    border-radius: 3.2vw;
                    > span {
                        font-size: 4.2vw;
                    }
                    img {
                        height: 4.2vw;
                    }
                }
            }
        }
        > .content-wrap {
            padding-bottom: 10vw;
            > .row {
                padding: 0 10vw;
                flex-direction: column;
                &:nth-child(2n + 1) {
                    flex-direction: column-reverse;
                }
                &:nth-child(2n) {
                    background-color: #fff;
                }
                > .left {
                    padding: 13vw 0 0;
                    text-align: center;
                }
                > .right {
                    padding: 13vw 0 0;
                    text-align: center;
                }
                h1 {
                    font-size: 6vw;
                    line-height: 8vw;
                }
                p {
                    font-size: 3.8vw;
                    line-height: 6vw;
                }
                h1 + p {
                    margin-top: 5vw;
                }
            }
        }
    }
}
