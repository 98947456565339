.banner-wrap {
    background-color: #775efb;
    text-align: center;
    height: 22.9vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > h1 {
        color: #fff;
        font-size: 3.3vw;
        font-family: 'NanumSquareRoundEB';
    }
    > p {
        color: #fff;
        font-size: 1.25vw;
        margin-top: 1.25vw;
    }
}

@media screen and (max-width: 800px) {
    .banner-wrap {
        height: 33.3vw;
        > h1 {
            font-size: 4.8vw;
        }
        > p {
            font-size: 2vw;
            margin-top: 2vw;
        }
    }
}