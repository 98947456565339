.wtet-wrap {
    background-image: url("../../assets/drawable-xxhdpi/wte_back.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    > .wte-top {
        text-align: center;
        > img {
            height: 33.5vw;
            margin: 6.7vw 0;
        }
    }
    > .wte-body {
        margin: 0 auto;
        width: 73vw;
        > .row {
            display: flex;
            flex-direction: row;
            > .one {
                flex: 1.5;
                text-align: center;
                > img {
                    height: 37.5vw;
                }
            }
            > .two {
                flex: 2;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            > .one + .two {
                text-align: right;
            }
            h1 {
                color: #fff;
                font-size: 3vw;
                margin-bottom: 0.7vw;
                font-family: "NanumSquareRoundEB";
            }
            p {
                color: #fff;
                font-size: 1.8vw;
                line-height: 3vw;
            }
        }
        > .row + .row {
            margin-top: 2.8vw;
        }
    }
    > .wte-bottom {
        text-align: center;
        margin-top: 10.4vw;
        > h1 {
            color: #fff;
            margin-bottom: 2.3vw;
            font-size: 3vw;
            line-height: 4.5vw;
        }
        div {
            > img {
                width: 7.5vw;
            }
            > .circle-wrap {
                display: flex;
                flex-direction: row;
                margin-top: 2.8vw;
                padding-bottom: 10.4vw;
                align-items: center;
                justify-content: center;
                > .circle {
                    margin: 0 2.4vw;
                    width: 14.5vw;
                    height: 14.5vw;
                    border-radius: 50%;
                    background-color: #ffffff19;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    > p {
                        color: #fff;
                        font-size: 1.6vw;
                        line-height: 2.4vw;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .wtet-wrap {
        > .wte-top {
            > img {
                height: 60vw;
                margin: 18.6vw 0 15.4vw;
            }
        }
        > .wte-body {
            width: 100vw;
            > .row {
                display: flex;
                flex-direction: column;
                > .one {
                    > img {
                        height: 96vw;
                        margin-bottom: 4.8vw;
                    }
                }
                > .two {
                    display: block;
                    text-align: center;
                }
                > .one + .two {
                    text-align: center;
                }
                &:nth-child(2n) {
                    flex-direction: column-reverse;
                }
                h1 {
                    font-size: 6.4vw;
                    margin-bottom: 4.8vw;
                }
                p {
                    font-size: 3.7vw;
                    line-height: 5vw;
                }
            }
            > .row + .row {
                margin-top: 18.6vw;
            }
        }

        > .wte-bottom {
            display: flex;
            flex-direction: column-reverse;
            > h1 {
                padding: 6.6vw 0 18.6vw;
                font-size: 6.4vw;
                line-height: 10vw;
            }
            > div {
                position: relative;
                > img {
                    width: 26vw;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                > .circle-wrap {
                    padding: 0;
                    > .circle {
                        width: 37.3vw;
                        height: 37.3vw;
                        margin: 0 6.5vw;
                        > p {
                            font-size: 4.8vw;
                            line-height: 6vw;;
                        }
                    }
                }
            }
        }
    }
}
