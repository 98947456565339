.top-wrap {
    padding-top: 3.4vw;
    padding-bottom: 2.8vw;
    > .in-wrap {
        max-width: 73vw;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        > .left-wrap {
            flex: 1;
            > a > img {
                height: 2vw;
            }
        }
        > .right-wrap {
            button {
                display: none;
            }
            > ul {
                list-style: none;
                > li {
                    float: left;
                    > a {
                        font-size: 1.3vw;
                        color: #000000;
                        > span {
                            font-family: 'NanumSquareRoundEB';
                        }
                    }
                    & + li {
                        margin-left: 3.7vw;
                    }
                    &.active {
                        > a {
                            color: #775efb;
                        }
                    }
                }
            }
        }
    }
    > .mobile-menu {
        display: none;
    }
}
.top-wrap.black {
    > .in-wrap {
        > .right-wrap {
            > ul {
                > li {
                    > a {
                        color: #ffffff80;
                    }
                    &.active {
                        > a {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

.top-wrap.purple {
    background-color: #775efb;
    > .in-wrap {
        > .right-wrap {
            > ul {
                position: relative;
                z-index: 10;
                > li {
                    > a {
                        color: #ffffff;
                    }
                    &.active {
                        > a {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .top-wrap.purple {
        background-color: #fff;
    }
    .top-wrap {
        padding-top: 8vw;
        padding-bottom: 4vw;
        > .in-wrap {
            max-width: 92vw;
            > .left-wrap {
                > a > img {
                    height: 3.8vw;
                }
            }
            > .right-wrap {
                button {
                    display: inherit;
                    border: none;
                    background: none;
                    width: 6.4vw;
                    height: 6.4vw;
                    > img {
                        width: 100%;
                    }
                }
                > ul {
                    display: none;
                }
            }
        }
        > .mobile-menu {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #00000099;
            z-index: 100;
            > .inner {
                margin-left: 22.6vw;
                background-color: #fff;
                height: 100vh;
                padding: 4vw 4vw 4vw 10vw;
                > .top {
                    text-align: right;
                    > a > img {
                        width: 6.4vw;
                        height: 6.4vw;
                    }
                }
                > .bottom {
                    margin-top: 10vw;
                    > ul {
                        list-style: none;
                        > li {
                            margin-bottom: 10vw;
                            > a {
                                color: #000;
                                > span {
                                    font-family: 'NanumSquareRoundEB';
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
